import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Button, Modal } from '@mantine/core'
import { memo } from 'react'
import { fontWeight500 } from '~/css/font'
import useMedia from '~/hooks/useMedia'
import {
  fill_horizontal_all_center,
  fill_vertical_all_center,
  fill_vertical_main_center,
  jc,
  pureGrid,
} from '~/modules/AppLayout/FlexGridCss'
import { useFirebaseMeState } from '~/modules/auth/containers/useFirebaseMeState'
import { useMeStore } from '~/modules/SDK/me/useMeStore'
import { fadeIn, fadeInTransformX } from '~/pages/eurex/_private/styleds'
import { ProductKey } from './ProductKey'
import { SetPassword } from './SetPassword'
import { useDisclosure } from '@mantine/hooks'
import { LoginBoard } from './LoginBoard'

//未成為會員畫面
export const UnregisteredView = memo<ReactProps>(function UnregisteredView() {
  const firebaseMe = useFirebaseMeState.useContainer()

  /** 會員代碼 */
  const userNumber = useMeStore(state => state.meUserState?.email)?.replace(
    '@win988.futures-ai.com',
    '',
  )

  return (
    <div
      css={css`
        ${fill_vertical_all_center};
        ${jc.spaceAround};
      `}
    >
      <infoStyleds.item>會員帳號&nbsp;{userNumber}</infoStyleds.item>

      <ProductKey />

      <Button
        css={css`
          width: 120px;
        `}
        variant='gradient'
        gradient={{ from: 'orange', to: 'red' }}
        onClick={() => firebaseMe.acts.logout()}
      >
        登出
      </Button>
    </div>
  )
})

export const Win988LoginView = memo<ReactProps>(function Win988LoginView(props) {
  const meFirebaseState = useMeStore(state => state.meFirebaseState)
  const { isPhone } = useMedia()

  const [opened, { open, close }] = useDisclosure(false)

  return (
    <styleds.Container className={props.className}>
      <styleds.DemoContent
        css={css`
          display: ${isPhone ? 'none' : ''};
        `}
      >
        <styleds.Left>
          <styleds.LeftSubTitle>TRADING MASTER</styleds.LeftSubTitle>
          <styleds.LeftTitle>領航者</styleds.LeftTitle>
        </styleds.Left>
      </styleds.DemoContent>

      <styleds.Sidebar
        css={css`
          width: ${!isPhone ? '500px' : '100%'};
        `}
      >
        <Modal
          opened={opened}
          onClose={close}
          centered
          withCloseButton={false}
          size='xs'
          css={ModalCss}
        >
          <SetPassword />
        </Modal>
        <styleds.LogoSloganContent>
          <styleds.LogoName src='win988/logo.png' />
          <styleds.Subtitle>
            <p>洞悉市場脈搏，引進財富航程</p>
          </styleds.Subtitle>
        </styleds.LogoSloganContent>

        <styleds.LogintContent>
          {!meFirebaseState && <LoginBoard />}
          {meFirebaseState && <UnregisteredView />}
        </styleds.LogintContent>

        <div
          css={css`
            grid-row: 8;
            ${fill_vertical_all_center};
          `}
        >
          {meFirebaseState && <styleds.LinkText onClick={open}>修改密碼</styleds.LinkText>}
          {!meFirebaseState && <div onClick={open}> &nbsp; </div>}
          <styleds.LinkText>聯繫客服</styleds.LinkText>
        </div>
      </styleds.Sidebar>
    </styleds.Container>
  )
})

const ModalCss = css`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1400;
  .mantine-Modal-body {
    border: 1px solid #577575;
    background-image: linear-gradient(285deg, #141822, #363e4e);
  }
`

const infoStyleds = {
  item: styled.p`
    ${fill_vertical_all_center};
    height: 32px;
    font-size: 16px;
    ${fontWeight500};
    margin: 4px;
    width: 264px;
    border-radius: 4px;
    background-color: #5d7c9daa;
  `,
}

const styleds = {
  Container: styled.div`
    ${fill_horizontal_all_center}
    position: fixed;
    z-index: 1300;
    background-color: #191d25;
    color: #ffffff;
  `,
  Left: styled.div`
    width: 308px;
    animation: ${fadeInTransformX} 2s;
  `,
  LeftSubTitle: styled.div`
    color: #ffffff;
    font-size: 28px;
  `,
  LeftTitle: styled.div`
    ${fill_horizontal_all_center}
    color: #ffffff;
    height: 72px;
    line-height: 72px;
    background-color: #456786;
    -webkit-text-stroke: 0.3px #ffffff;
    padding: 4px 12px;
  `,
  Sidebar: styled.div`
    ${fill_vertical_all_center};
    ${pureGrid};
    grid-template-rows: repeat(8, 12.5%);
    grid-template-columns: 100%;
    background-image: linear-gradient(285deg, #141822, #363e4e);
    padding: 24px;
    font-weight: 900;
    animation: ${fadeIn} 3s;
  `,
  DemoContent: styled.div`
    ${fill_vertical_main_center};
    background-color: #cccccc;
    width: calc(100% - 500px);
    background-image: linear-gradient(90deg, #252525, #252525aa, #252525aa),
      url('win988/login-view.png');
    background-position: left;
    background-size: cover;
    font-weight: 900;
    font-size: 48px;
    padding: 32px;
    animation: ${fadeIn} 2s;
  `,
  LogoName: styled.img`
    width: 148px;
    grid-row: 1 / 3;
  `,
  LogoSloganContent: styled.div`
    grid-row: 2 / 4;
    ${fill_vertical_all_center};
  `,
  Subtitle: styled.div`
    font-size: 18px;
    line-height: 8px;
  `,
  LogintContent: styled.div`
    ${fill_vertical_all_center};
    height: 100%;
    gap: 16px;
    grid-row: 4 / 8;
  `,
  LinkText: styled.div`
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  `,
}
