import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { eurexIndicatorsValueStorage } from '../example/eurexIndicatorsValueStorage'

export const valueProvider = createIndicator({
  displayName: '-',
  id: 'tc1688-value',

  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const length1 = 5
      const length2 = 20
      const length3 = 60
      const length4 = 120

      const close = this.ohlc.closeArray
      const high = this.ohlc.highArray
      const low = this.ohlc.lowArray
      const volumeValue = this.PineJS.Std.volume(this._context)
      const volume = this._context.new_var(volumeValue)
      close.get(200)
      high.get(200)
      low.get(200)
      volume.get(200)

      const ma5Value = this.average(close, length1)
      const ma20Value = this.average(close, length2)
      const ma60Value = this.average(close, length3)
      const ma120Value = this.average(close, length4)
      const ma5 = this._context.new_var(ma5Value)
      const ma20 = this._context.new_var(ma20Value)
      const ma60 = this._context.new_var(ma60Value)
      const ma120 = this._context.new_var(ma120Value)
      ma5.get(200)
      ma20.get(200)
      ma60.get(200)
      ma120.get(200)

      // Mov. Avg.
      eurexIndicatorsValueStorage.ma5 = ma5Value
      eurexIndicatorsValueStorage.ma20 = ma20Value
      eurexIndicatorsValueStorage.ma60 = ma60Value
      eurexIndicatorsValueStorage.ma120 = ma120Value

      // Bias
      eurexIndicatorsValueStorage.bias5 = this.bias(length1)
      eurexIndicatorsValueStorage.bias20 = this.bias(length2)
      eurexIndicatorsValueStorage.bias60 = this.bias(length3)
      eurexIndicatorsValueStorage.bias120 = this.bias(length4)

      // Slopes = (ma[0] - ma[1]) / ma[1] ??
      eurexIndicatorsValueStorage.slope5 = ma5.get(0) - ma5.get(1)
      eurexIndicatorsValueStorage.slope20 = ma20.get(0) - ma20.get(1)
      eurexIndicatorsValueStorage.slope60 = ma60.get(0) - ma60.get(1)
      eurexIndicatorsValueStorage.slope120 = ma120.get(0) - ma120.get(1)

      // Offset value
      eurexIndicatorsValueStorage.offsetValue5 = ma5.get(length1 - 1)
      eurexIndicatorsValueStorage.offsetValue20 = ma20.get(length2 - 1)
      eurexIndicatorsValueStorage.offsetValue60 = ma60.get(length3 - 1)
      eurexIndicatorsValueStorage.offsetValue120 = ma120.get(length4 - 1)

      //rsv
      eurexIndicatorsValueStorage.rsv5 =
        ((close.get(0) - this.lowest(low, length1)) /
          (this.highest(high, length1) - this.lowest(low, length1))) *
        100
      eurexIndicatorsValueStorage.rsv20 =
        ((close.get(0) - this.lowest(low, length2)) /
          (this.highest(high, length2) - this.lowest(low, length2))) *
        100

      eurexIndicatorsValueStorage.rsv60 =
        ((close.get(0) - this.lowest(low, length3)) /
          (this.highest(high, length3) - this.lowest(low, length3))) *
        100

      return [NaN]
    },
  },
  metainfo: {
    plots: [
      {
        id: 'plot_0',
        type: 'line',
      },
    ],
    defaults: {
      //樣式調整
      styles: {
        plot_0: {
          linestyle: 0,
          linewidth: 2,
          plottype: 0,
          trackPrice: !1,
          transparency: 100,
          visible: !1,
          color: '#fe80c0',
        },
      },
    },
    is_price_study: !0,
    scriptIdPart: '',
    inputs: [],
  },
})
