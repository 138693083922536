import { css } from '@emotion/react'
import { memo } from 'react'
import { ThemeToggle } from '~/components/theme/ThemeToggle'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { agentConfigs2 } from '~/configs/agentConfigs'

import { fill_horizontal_cross_center, flex } from '~/modules/AppLayout/FlexGridCss'
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch'
import { store } from '../heineken_template/_private/store'
import { proxy } from 'valtio'
import { FuiUserAvatar } from '~/pages/heineken_template/components/FuiUserAvatar'
import { Preset_Topbar } from '~/pages/heineken_template/_preset/preset_Topbar'
import { FubonLogoutView } from './fubon_LogoutView'
import styled from '@emotion/styled'
import useMedia from '~/hooks/useMedia'

export const fubon_store = proxy({ userAvatar: new FuiUserAvatar() })

export const Fubon_TopBar = memo<ReactProps>(function Fubon_TopBar() {
  const theme = useThemeStore(t => t.theme)
  const { isPc } = useMedia()
  return (
    <styleds.container className={theme}>
      <img
        src={theme === 'light' ? agentConfigs2['fubon@web'].favicon : '/fubon/favicon-dark.png'}
        css={css`
          width: 40px;
          height: 40px;
        `}
      />

      {isPc && <div>{agentConfigs2['fubon@web'].title}</div>}
      {isPc && (
        <div
          css={css`
            ${flex.h.crossCenter};
            margin-left: auto;
          `}
        >
          <ChartingServerSwitch charting={store.charting} />
          <ThemeToggle
            onClick={event => {
              store.charting.setThemeMode(useThemeStore.getState().theme)
              store.charting.updateFromThemeMode()
            }}
          />

          <fubon_store.userAvatar.AvatarModal
            cssset={css`
              width: 280px;
              height: 360px;
              transition: 0.3s;
            `}
          >
            <FubonLogoutView />
          </fubon_store.userAvatar.AvatarModal>
        </div>
      )}

      {!isPc && (
        <Preset_Topbar
          showLeftBurger
          componentsInRight={
            <styleds.container>
              <ChartingServerSwitch charting={store.charting} />
              <ThemeToggle
                onClick={event => {
                  store.charting.setThemeMode(useThemeStore.getState().theme)
                  store.charting.updateFromThemeMode()
                }}
              />

              <fubon_store.userAvatar.AvatarModal
                cssset={css`
                  width: 280px;
                  height: 400px;
                  transition: 0.3s;
                `}
              >
                <FubonLogoutView />
              </fubon_store.userAvatar.AvatarModal>
            </styleds.container>
          }
        ></Preset_Topbar>
      )}
    </styleds.container>
  )
})

export const styleds = {
  container: styled.div`
    ${fill_horizontal_cross_center};
    border-bottom: 2px solid #0bceff;
    padding: 0px 4px;

    &.dark {
      background-color: #2e343a;
    }
    &.light {
      background-color: #fafafa;
    }
  `,
}
