import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Button, PasswordInput, TextInput } from '@mantine/core'
import { memo } from 'react'
import { fontWeight500 } from '~/css/font'
import { useText } from '~/hooks/useText'
import { fill_vertical_all_center } from '~/modules/AppLayout/FlexGridCss'
import { useMeStore } from '~/modules/SDK/me/useMeStore'
import { useFirebaseMeState } from '~/modules/auth/containers/useFirebaseMeState'

export const LoginBoard = memo<ReactProps>(function View() {
  const account = useText()
  const password = useText()
  const auth = useFirebaseMeState.useContainer()
  const agentConfig = useMeStore(state => state.agentConfig)

  return (
    <classes.container>
      <classes.titleContent>會員登入</classes.titleContent>
      <TextInput
        placeholder='請輸入帳號'
        withAsterisk
        value={account.value}
        onChange={account.acts.onChange}
      />
      <PasswordInput
        placeholder='請輸入密碼'
        description=''
        withAsterisk
        value={password.value}
        onChange={password.acts.onChange}
        css={css`
          width: 180px;
        `}
      />

      <classes.errorContent>
        {auth.error && <div>發生錯誤：請確認帳號或密碼</div>}
      </classes.errorContent>

      <Button
        disabled={auth.isAuthorizing}
        onClick={() =>
          auth.acts.loginWithMailAndPassword(
            (account.value ?? '') + agentConfig.emailSuffix,
            password.value ?? '',
          )
        }
        css={css`
          width: 180px;
        `}
      >
        登入
      </Button>
    </classes.container>
  )
})

const classes = {
  container: styled.div`
    ${fill_vertical_all_center};
    ${fontWeight500};
    padding: 0px 16px;
    width: 280px;
    height: 240px;
    background-color: #2b3545;
    border-radius: 8px;
    gap: 8px;
  `,
  errorContent: styled.div`
    color: #ff0000;
    height: 24px;
    font-size: 14px;
  `,
  titleContent: styled.div``,
}
