import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Button, PasswordInput } from '@mantine/core'
import { memo, useState } from 'react'
import { apirc } from '~/configs/apirc'
import {
  fill_horizontal_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'

//密碼規則: 需>=6個字元

export const SetPassword = memo<ReactProps>(function SetPassword() {
  const [password, setPassword] = useState('')
  const [checkPassword, setCheckPassword] = useState('')
  const [changeComple, setChangeComple] = useState(false)

  const isEmpty = password === ''
  const checkNotSame = checkPassword !== password

  const pwNotEnough = password.length < 6
  const pwError = !isEmpty && pwNotEnough
  const checkPwError = checkPassword !== '' && checkNotSame

  return (
    <styleds.container>
      <styleds.titleContent>修改密碼</styleds.titleContent>
      <PasswordInput
        placeholder='請輸入新密碼'
        withAsterisk
        error={pwError ? '密碼需要大於6個字元' : null}
        onChange={event => {
          setPassword(event.currentTarget.value)
        }}
        css={css`
          width: 180px;
          height: 60px;
        `}
      />
      <PasswordInput
        placeholder='確認新密碼'
        withAsterisk
        onChange={event => {
          setCheckPassword(event.currentTarget.value)
        }}
        error={checkPwError ? '兩者密碼不相同' : null}
        css={css`
          width: 180px;
          height: 60px;
        `}
      />
      {changeComple ? (
        <styleds.tipContent>修改完成 請重新登入</styleds.tipContent>
      ) : (
        <Button
          onClick={() => {
            apirc.me.api.changePassword.fetch({
              password: checkPassword,
            })
            setChangeComple(true)
          }}
          disabled={checkNotSame || isEmpty || pwNotEnough}
          css={css`
            height: 32px;
          `}
        >
          修改密碼
        </Button>
      )}
    </styleds.container>
  )
})

const styleds = {
  container: styled.div`
    ${fill_vertical_cross_center};
    width: 280px;
    height: 188px;
    gap: 4px;
  `,
  titleContent: styled.div`
    height: 24px;
    width: 180px;
    font-size: 14px;
    color: #fafafa;
  `,
  tipContent: styled.div`
    ${fill_horizontal_all_center};
    height: 32px;
    width: 180px;
    background-color: #379b3b66;
    font-size: 14px;
    border-radius: 4px;
    transition: 0.5s;
    color: #fafafa;
  `,
}
