import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Avatar, Button, Divider, PasswordInput, TextInput } from '@mantine/core'
import { memo, useState } from 'react'
import { useThemeStore } from '~/components/theme/useThemeStore'
import {
  fill_horizontal_all_center,
  fill_vertical_all_center,
  fill_vertical_cross_center,
  jc,
} from '~/modules/AppLayout/FlexGridCss'
import { useFirebaseMeState } from '~/modules/auth/containers/useFirebaseMeState'
import { MeTypes } from '~/modules/SDK/me/MeTypes'
import { useMeStore } from '~/modules/SDK/me/useMeStore'
import dayAPI from '~/utils/dayAPI'

/** 需要抽出當共用funtcion */
const getMatchedSubscription = (
  subscriptions: MeTypes.Subscription[],
  agentName: string, // target name
  product: string, // target product name
): MeTypes.Subscription | null => {
  for (const subscription of subscriptions) {
    if (subscription.agentName === agentName && subscription.productName === product) {
      return subscription
    }
  }
  return null
}

export const FubonLogoutView = memo<ReactProps>(function FubonLogoutView() {
  const firebaseMe = useFirebaseMeState.useContainer()
  const theme = useThemeStore(s => s.theme)

  const { meUserState } = useMeStore()
  /** 產品名稱 */
  const agentName = useMeStore(state => state.agentName)

  /** product名稱 */
  const produtName = useMeStore(state => state.product)

  /** 會員代碼 */
  const userNumber = useMeStore(state => state.meUserState?.code)

  /** 會員信箱 */
  const email = useMeStore(state => state.meUserState?.email)

  /** 會員名稱 */
  const userName = email?.replace('@fubon.futures-ai.com', '')

  const photoUrl = useMeStore(state => state.meUserState?.photo)

  /** 會員大頭貼 */
  const userPicture = (
    <Avatar
      src={photoUrl}
      size={48}
      radius='lg'
      alt="it's me"
    />
  )

  let matchedSubscription = null
  if (meUserState?.subscriptions) {
    matchedSubscription = getMatchedSubscription(meUserState?.subscriptions, agentName, produtName)
  }
  /** 到期日期 */
  const expDate = matchedSubscription && dayAPI(matchedSubscription.expiredAt).format('YYYY-MM-DD')

  const [password, setPassword] = useState('')
  const [checkPassword, setCheckPassword] = useState('')
  return (
    <styleds.container className={theme}>
      <styleds.topContent className={theme}>
        <p>{userPicture}</p>
        <p>{userName}</p>
      </styleds.topContent>
      <styleds.infoContent>
        <p>會員代碼 {userNumber}</p>
        <p>會員帳號 {userName}</p>
        <p>到期日期 {expDate}</p>
      </styleds.infoContent>
      <styleds.passwordContent>
        <PasswordInput
          placeholder='請輸入新密碼'
          withAsterisk
          onChange={event => {
            setPassword(event.currentTarget.value)
          }}
          css={css`
            width: 180px;
          `}
        />
        <PasswordInput
          placeholder='請再次輸入新密碼'
          withAsterisk
          onChange={event => {
            setCheckPassword(event.currentTarget.value)
          }}
          error={checkPassword !== password}
          css={css`
            width: 180px;
          `}
        />
        <Button
          onClick={() => {
            //送出修改密碼
          }}
          disabled={checkPassword !== password}
        >
          修改密碼
        </Button>
        <Divider
          my='sm'
          css={sividerCss}
        />
      </styleds.passwordContent>

      <styleds.logoutButton onClick={() => firebaseMe.acts.logout()}>登出</styleds.logoutButton>
    </styleds.container>
  )
})

const sividerCss = css`
  width: 280px;
  height: 8px;
  &.mantine-1a1e294 {
    margin-top: 0;
    margin-bottom: 0;
  }
`
const styleds = {
  container: styled.div`
    ${fill_vertical_cross_center};
    width: 280px;
    height: 400px;
    border-radius: 10px;
    gap: 8px;

    &.dark {
      background: linear-gradient(45deg, #25262b, #15161e);
      color: #efefef;
    }
    &.light {
      background: linear-gradient(45deg, #ffffff, #fafafa);
      color: #252525;
    }
  `,

  topContent: styled.div`
    ${fill_vertical_cross_center};
    width: 100%;
    height: 88px;
    padding: 8px;

    border-bottom: 2px solid #19c9fc;
    background-size: 100%;
    background-position: 50% 100%;
    border-radius: 10px 10px 0 0;
    & > p {
      padding: 4px;
      font-size: 14px;
      line-height: 14px;
      margin: 0px;
    }

    &.dark {
      background-image: linear-gradient(90deg, #25253b52, #212136, #25253b5e);
    }
    &.light {
      background-image: linear-gradient(90deg, #b9e4fb, #d5edf7, #f9f9f9);
    }
  `,
  infoContent: styled.div`
    ${fill_vertical_cross_center};
    height: 92px;
    gap: 4px;
    & > p {
      width: 186px;
      padding: 8px;
      font-size: 16px;
      line-height: 12px;
      margin: 0px;
      border-radius: 5px;
      transition: 0.3s;
    }
  `,
  passwordContent: styled.div`
    ${fill_vertical_all_center};
    ${jc.spaceAround};
    width: 280px;
    height: 156px;
  `,
  logoutButton: styled.div`
    ${fill_horizontal_all_center};
    width: 240px;
    height: 30px;
    border-radius: 6px;
    background-color: #9b4137;
    color: #efefef;
    &:hover {
      background-color: #9b4137aa;
      cursor: pointer;
    }
  `,
}
