import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'
import { isNaN } from 'lodash'

export const daddy960_tc1688_macdImpluseTv = createIndicator({
  id: 'daddy960-tc1688-macdImpluse-tv',
  displayName: 'tc1688_1-3',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const calcSmma = (series: PineJS.NewVar<number>, length: number): number => {
        const smma = context.new_var()
        smma.get(length)

        if (isNaN(smma.get(1))) {
          smma.set(this.average(series, length))
        } else {
          const smmaValue = (smma.get(1) * (length - 1) + series.get(0)) / length
          smma.set(smmaValue)
        }
        return smma.get(0)
      }

      const calcZlEma = (series: PineJS.NewVar<number>, length: number): number => {
        const ema1 = context.new_var()

        series.get(length)
        ema1.get(length)

        ema1.set(this.exponentialAverage(series, length))
        const ema2 = this.exponentialAverage(ema1, length)
        const d = ema1.get(0) - ema2
        return ema1.get(0) + d
      }

      // Input params
      const maLength = this._input(0)
      const signalLength = this._input(1)

      //#region Init
      const high = this.ohlc.highArray
      const low = this.ohlc.lowArray
      const price = context.new_var()
      const macd = context.new_var()

      high.get(100)
      low.get(100)
      price.get(100)
      macd.get(100)

      price.set(this.weightedClose())
      //#endregion

      // Calculate
      const ssmaHigh = calcSmma(high, maLength)
      const ssmaLow = calcSmma(low, maLength)
      const zlema = calcZlEma(price, maLength)

      const macdValue = zlema > ssmaHigh ? zlema - ssmaHigh : zlema < ssmaLow ? zlema - ssmaLow : 0
      macd.set(macdValue)

      const signal = this.average(macd, signalLength)

      return [
        price.get(0) > zlema && price.get(0) > ssmaHigh ? macdValue : NaN,
        price.get(0) > zlema && price.get(0) < ssmaHigh ? macdValue : NaN,
        price.get(0) < zlema && price.get(0) < ssmaLow ? macdValue : NaN,
        price.get(0) < zlema && price.get(0) > ssmaLow ? macdValue : NaN,
        signal,
      ]
    },
  },
  metainfo: {
    is_price_study: false,
    _metainfoVersion: 42,
    isTVScript: false,
    isTVScriptStub: false,
    is_hidden_study: false,

    plots: [
      { id: 'macdLime', type: 'line' },
      { id: 'macdGreen', type: 'line' },
      { id: 'macdRed', type: 'line' },
      { id: 'macdOrange', type: 'line' },
      { id: 'signal', type: 'line' },
    ],

    defaults: {
      styles: {
        macdLime: {
          linestyle: 0,
          linewidth: 3,
          plottype: 1,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#66ff1a',
        },
        macdGreen: {
          linestyle: 0,
          linewidth: 3,
          plottype: 1,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#006400',
        },
        macdRed: {
          linestyle: 0,
          linewidth: 3,
          plottype: 1,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#cc0000',
        },
        macdOrange: {
          linestyle: 0,
          linewidth: 3,
          plottype: 1,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#Ff8c00',
        },
        signal: {
          linestyle: 0,
          linewidth: 2,
          plottype: 2,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#2196f3',
        },
      },
      precision: 2,
      inputs: {
        maLength: 13,
        signalLength: 4,
      },
    },
    styles: {
      signal: {
        title: 'Signal',
        histogramBase: 0,
        joinPoints: false,
      },

      macdLime: {
        title: 'Impulse MACD"',
        histogramBase: 0,
        joinPoints: false,
      },
      macdGreen: {
        title: 'Impulse MACD"',
        histogramBase: 0,
        joinPoints: false,
      },
      macdRed: {
        title: 'Impulse MACD"',
        histogramBase: 0,
        joinPoints: false,
      },
      macdOrange: {
        title: 'Impulse MACD"',
        histogramBase: 0,
        joinPoints: false,
      },
    },

    inputs: [
      {
        id: 'maLength',
        name: 'MA Length',
        defval: 13,
        type: 'integer',
        min: 1,
        max: 500,
        step: 1,
      },
      {
        id: 'signalLength',
        name: 'Signal MA Length',
        defval: 4,
        type: 'integer',
        min: 1,
        max: 500,
        step: 1,
      },
    ],
  },
})
